import { ActionType, getType } from 'typesafe-actions';
import { imUserConfigActions } from '../actions';
import { pipelineRoleColumns } from '../constants';
import { RequestState } from '../constants/request-state';
import { AlertOption, StatisticsAlertOption } from '../types/amr-pipeline/enums/AlertOption';
import { UserConfigState } from '../types/state/UserConfigState';
import {
    CompanyAlertsConfig,
    ColumnsConfig,
    FilterConfig,
    ImAlertConfig,
    SecondaryStatsAlertsConfig,
    SummaryDailyAlertConfig,
    TransactionAlertConfig,
    WeeklyStatsAlertConfig,
    DailyStatsAlertsConfig,
} from '../types/user-config/UserConfig';
import { UserConfigType } from '../types/user-config/UserConfigType';
import { user } from '../user';
import { mapConfig } from '../utils/amr-pipeline.utils';

export const userConfigInitialState: UserConfigState = {
    isLoading: false,
    isUpdating: false,
    isLoadingDefault: false,
    isDeleting: false,
    columnsConfig: [],
    filtersConfig: {
        [UserConfigType.imFilter]: {
            type: UserConfigType.imFilter,
            value: [],
        },
        [UserConfigType.apFilter]: {
            type: UserConfigType.apFilter,
            value: [],
        },
    },
    imAlertConfig: {
        type: UserConfigType.imAlert,
        value: {
            alertOption: AlertOption.Never,
        },
    },
    newTransactionAlertConfig: {
        type: UserConfigType.newPublishedTransactionAlert,
        value: {
            alertOption: AlertOption.Never,
        },
    },
    weeklyStatsAlertConfig: {
        type: UserConfigType.weeklyStatsEmail,
        value: {
            emailOption: StatisticsAlertOption.Never,
        },
    },
    transactionAlertConfig: {
        type: UserConfigType.transactionAlert,
        value: [],
    },
    apAlertConfig: {
        type: UserConfigType.arrangerPipelineAlert,
        value: {
            alertOption: AlertOption.Never,
        },
    },
    cloManagerAnalyticsAlert: {
        type: UserConfigType.cloManagerAnalyticsAlert,
        value: [],
    },
    cloManagerIssuanceMonitorAlert: {
        type: UserConfigType.cloManagerIssuanceMonitorAlert,
        value: [],
    },
    cloManagerArrangerPipelineAlert: {
        type: UserConfigType.cloManagerArrangerPipelineAlert,
        value: [],
    },
    cloManagerBwicAlert: {
        type: UserConfigType.cloManagerBwicAlert,
        value: [],
    },
    cloManagerDealerInventoryAlert: {
        type: UserConfigType.cloManagerDealerInventoryAlert,
        value: [],
    },
    bankAnalyticsAlert: {
        type: UserConfigType.bankAnalyticsAlert,
        value: [],
    },
    bankDealerInventoryAlert: {
        type: UserConfigType.bankDealerInventoryAlert,
        value: [],
    },
    bankIssuanceMonitorAlert: {
        type: UserConfigType.bankIssuanceMonitorAlert,
        value: [],
    },
    bankArrangerPipelineAlert: {
        type: UserConfigType.bankArrangerPipelineAlert,
        value: [],
    },
    summaryDailyAlertConfig: {
        type: UserConfigType.summaryDailyAlerts,
        value: [],
    },
    columnsDefaultConfig: [],
};

function filterForRoles(configs: ColumnsConfig[]) {
    return configs.map(el => ({
        ...el,
        value: el.value.filter(({ name }) => {
            const { includeFor = [], excludeFor = [] } = pipelineRoleColumns[name] || {};

            if (excludeFor.some((role: string) => user.hasRoles(role))) {
                return false;
            }

            return !includeFor.length || includeFor.some((role: string) => user.hasRoles(role));
        }),
    }));
}

export const imUserConfig = (
    state = userConfigInitialState,
    action: ActionType<(typeof imUserConfigActions)[keyof typeof imUserConfigActions]>,
): UserConfigState => {
    switch (action.type) {
        case getType(imUserConfigActions.getUserConfig):
            return {
                ...state,
                isLoading: true,
            };
        case getType(imUserConfigActions.getUserConfigResult):
        case getType(imUserConfigActions.updateUserConfigResult): {
            const config = mapConfig(action.payload.userConfig);
            const existingConfigTypes = config.map(({ type }) => type);
            const columnsConfig = config.filter(({ type }) =>
                [UserConfigType.imColumnsClassTab, UserConfigType.imColumnsDealTab].includes(type),
            ) as ColumnsConfig[];

            const dealsPipelineFilterConfig = config.find(({ type }) =>
                [UserConfigType.imFilter].includes(type),
            ) as FilterConfig;
            const arrangerPipelineFilterConfig = config.find(({ type }) =>
                [UserConfigType.apFilter].includes(type),
            ) as FilterConfig;
            const imAlertConfig = config.find(({ type }) => [UserConfigType.imAlert].includes(type)) as ImAlertConfig;
            const newTransactionAlertConfig = config.find(({ type }) =>
                [UserConfigType.newPublishedTransactionAlert].includes(type),
            ) as ImAlertConfig;
            const weeklyStatsAlertConfig = config.find(({ type }) =>
                [UserConfigType.weeklyStatsEmail].includes(type),
            ) as WeeklyStatsAlertConfig;
            const transactionAlertConfig = config.find(({ type }) =>
                [UserConfigType.transactionAlert].includes(type),
            ) as TransactionAlertConfig;
            const summaryDailyAlertConfig = config.find(({ type }) =>
                [UserConfigType.summaryDailyAlerts].includes(type),
            ) as SummaryDailyAlertConfig;
            const apAlertConfig = config.find(({ type }) =>
                [UserConfigType.arrangerPipelineAlert].includes(type),
            ) as ImAlertConfig;
            const cloManagerAnalyticsAlert = config.find(({ type }) =>
                [UserConfigType.cloManagerAnalyticsAlert].includes(type),
            ) as CompanyAlertsConfig;
            const cloManagerIssuanceMonitorAlert = config.find(({ type }) =>
                [UserConfigType.cloManagerIssuanceMonitorAlert].includes(type),
            ) as CompanyAlertsConfig;
            const cloManagerArrangerPipelineAlert = config.find(({ type }) =>
                [UserConfigType.cloManagerArrangerPipelineAlert].includes(type),
            ) as CompanyAlertsConfig;
            const cloManagerBwicAlert = config.find(({ type }) =>
                [UserConfigType.cloManagerBwicAlert].includes(type),
            ) as SecondaryStatsAlertsConfig;
            const cloManagerDealerInventoryAlert = config.find(({ type }) =>
                [UserConfigType.cloManagerDealerInventoryAlert].includes(type),
            ) as SecondaryStatsAlertsConfig;
            const bankAnalyticsAlert = config.find(({ type }) =>
                [UserConfigType.bankAnalyticsAlert].includes(type),
            ) as DailyStatsAlertsConfig;
            const bankArrangerPipelineAlert = config.find(({ type }) =>
                [UserConfigType.bankArrangerPipelineAlert].includes(type),
            ) as CompanyAlertsConfig;
            const bankIssuanceMonitorAlert = config.find(({ type }) =>
                [UserConfigType.bankIssuanceMonitorAlert].includes(type),
            ) as SecondaryStatsAlertsConfig;
            const bankDealerInventoryAlert = config.find(({ type }) =>
                [UserConfigType.bankDealerInventoryAlert].includes(type),
            ) as SecondaryStatsAlertsConfig;

            const filtersConfig = {
                [UserConfigType.imFilter]: dealsPipelineFilterConfig || state.filtersConfig[UserConfigType.imFilter],
                [UserConfigType.apFilter]: arrangerPipelineFilterConfig || state.filtersConfig[UserConfigType.apFilter],
            };

            return {
                ...state,
                isLoading: false,
                isLoadingDefault: false,
                isUpdating: false,
                isDeleting: false,
                columnsConfig: [
                    ...state.columnsConfig.filter(({ type }) => !existingConfigTypes.includes(type)),
                    ...filterForRoles(columnsConfig),
                ],
                filtersConfig: filtersConfig,
                imAlertConfig: imAlertConfig || state.imAlertConfig,
                newTransactionAlertConfig:
                    newTransactionAlertConfig || userConfigInitialState.newTransactionAlertConfig,
                weeklyStatsAlertConfig: weeklyStatsAlertConfig || state.weeklyStatsAlertConfig,
                transactionAlertConfig: transactionAlertConfig || userConfigInitialState.transactionAlertConfig,
                summaryDailyAlertConfig: summaryDailyAlertConfig || userConfigInitialState.summaryDailyAlertConfig,
                apAlertConfig: apAlertConfig || userConfigInitialState.apAlertConfig,
                cloManagerAnalyticsAlert: cloManagerAnalyticsAlert || userConfigInitialState.cloManagerAnalyticsAlert,
                cloManagerIssuanceMonitorAlert:
                    cloManagerIssuanceMonitorAlert || userConfigInitialState.cloManagerIssuanceMonitorAlert,
                cloManagerArrangerPipelineAlert:
                    cloManagerArrangerPipelineAlert || userConfigInitialState.cloManagerArrangerPipelineAlert,
                cloManagerBwicAlert:
                    cloManagerBwicAlert || userConfigInitialState.cloManagerBwicAlert,
                cloManagerDealerInventoryAlert:
                    cloManagerDealerInventoryAlert || userConfigInitialState.cloManagerDealerInventoryAlert,
                bankAnalyticsAlert:
                    bankAnalyticsAlert || userConfigInitialState.bankAnalyticsAlert,
                bankArrangerPipelineAlert:
                    bankArrangerPipelineAlert || userConfigInitialState.bankArrangerPipelineAlert,
                bankIssuanceMonitorAlert:
                    bankIssuanceMonitorAlert || userConfigInitialState.bankIssuanceMonitorAlert,
                bankDealerInventoryAlert:
                    bankDealerInventoryAlert || userConfigInitialState.bankDealerInventoryAlert,
            };
        }
        case getType(imUserConfigActions.getUserFilterConfigResult): {
            const config = mapConfig(action.payload.userConfig);
            const dealsPipelineFilterConfig = config.find(({ type }) =>
                [UserConfigType.imFilter].includes(type),
            ) as FilterConfig;
            const arrangerPipelineFilterConfig = config.find(({ type }) =>
                [UserConfigType.apFilter].includes(type),
            ) as FilterConfig;

            const filtersConfig = {
                [UserConfigType.imFilter]: dealsPipelineFilterConfig || state.filtersConfig[UserConfigType.imFilter],
                [UserConfigType.apFilter]: arrangerPipelineFilterConfig || state.filtersConfig[UserConfigType.apFilter],
            };

            return {
                ...state,
                isUpdating: false,
                filtersConfig,
            };
        }
        case getType(imUserConfigActions.getDefaultUserColumnsConfig):
            return {
                ...state,
                isLoadingDefault: true,
            };
        case getType(imUserConfigActions.getDefaultUserColumnsConfigResult): {
            const { columnConfig } = action.payload;
            const resetState = {
                ...state,
                isLoadingDefault: false,
            };

            if (!columnConfig) {
                return resetState;
            }

            const config = mapConfig(columnConfig);
            const columnsDefaultConfig = filterForRoles(config);

            return {
                ...resetState,
                columnsDefaultConfig,
            };
        }
        case getType(imUserConfigActions.updateUserConfig):
        case getType(imUserConfigActions.createUserFilter):
        case getType(imUserConfigActions.updateUserFilter):
        case getType(imUserConfigActions.updateUserFilterEmailAlerts):
        case getType(imUserConfigActions.saveTransactionAlerts):
        case getType(imUserConfigActions.saveCompanyAlerts):
            return {
                ...state,
                isUpdating: true,
            };
        case getType(imUserConfigActions.createUserFilterResult):
        case getType(imUserConfigActions.updateUserFilterResult):
            return {
                ...state,
                isUpdating: false,
            };

        case getType(imUserConfigActions.setUserFilterParamsResult): {
            const { filtersConfig } = state;
            const { requestResult, userConfigFilter, filterType } = action.payload;

            if (requestResult === RequestState.failure || !userConfigFilter) {
                return state;
            }

            let filterValues = filtersConfig[filterType].value;

            if (userConfigFilter.default) {
                filterValues = filterValues.map(filterValue => ({
                    ...filterValue,
                    default: false,
                }));
            }

            const value = filterValues
                .filter(x => x.referenceName !== userConfigFilter.referenceName)
                .concat(userConfigFilter);

            return {
                ...state,
                isUpdating: false,
                filtersConfig: {
                    ...filtersConfig,
                    [filterType]: {
                        ...filtersConfig[filterType],
                        value,
                    },
                },
            };
        }
        case getType(imUserConfigActions.deleteUserFilter):
            return {
                ...state,
                isDeleting: true,
            };
        case getType(imUserConfigActions.deleteUserFilterResult): {
            const { requestResult, referenceName, filterType } = action.payload;

            if (requestResult === RequestState.failure) {
                return state;
            }

            return {
                ...state,
                isDeleting: false,
                filtersConfig: {
                    ...state.filtersConfig,
                    [filterType]: {
                        ...state.filtersConfig[filterType],
                        value: state.filtersConfig[filterType].value.filter(x => x.referenceName !== referenceName),
                    },
                },
            };
        }
        case getType(imUserConfigActions.reset): {
            return userConfigInitialState;
        }
        default:
            return state;
    }
};
